import React from "react";

import './styles.css';

const Description: React.FC = () => {
  return (
    <div className='description'>
      <div className='description-content'>
        <div className='description-text'>
          <p>Desvende os Mistérios da Sua Existência!</p>
          <p>Explore o fascinante mundo da numerologia com o</p>
          <p>nosso livro e descubra as incríveis conexões entre os</p>
          <p>números e o seu destino. Adquira o seu MANUAL DE</p>
          <p>NUMEROLOGIA agora e comece a decifrar os códigos</p>
          <p>que moldam a sua vida!</p>
        </div>
        <strong>O OUE VOCÊ VAI APRENDER:</strong>
        <div className='description-list'>
          <ul>
            <li>Numerologia Pitagórica.</li>            
            <li>A Travessia dos 9 Portais do Conhecimento.</li>            
            <li>A Trajetória Evolutiva do Homem rumo ao Autoconhecimento.</li>            
            <li>Os Elementos da Análise Numerológica.</li>
            <li>A Mochila.</li>
            <li>A Cabala da Vida.</li>
            <li>Numerologia Projetiva.</li>
            <li>Numerologia Tântrica.</li>
            <li>Programa para calcular o Mapa Numerológico.</li>
            <li>e muito mais...</li>      
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Description;
