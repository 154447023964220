import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import ebookComMoldura from '../../../assets/images/ebook_moldura.png';

import './styles.css';

interface Props {  
  pageName: string, 
}
type props = Props

const BuyNow = ({ pageName } : props) => {
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  //const linkAmazon = 'https://www.amazon.com.br/Manual-Numerologia-Ingrid-Dalila-Engel-ebook/dp/B0CP2XKT96/ref=sr_1_1?crid=2MF341MJESWFP&keywords=manual+de+numerologia&qid=1702656664&sprefix=%2Caps%2C411&sr=8-1';
  const linkClubeDosAutores = 'https://clubedeautores.com.br/livro/manual-de-numerologia';

  return (
    <div className='buy-now'>
      <div className='buy-now-content'>        
        <div className='image-block'>
          <div className='buy-now-image'>
            <img src={ebookComMoldura} alt="capa-ebook"/>
          </div>
        </div>
        <div className='text-block'>
          <div className='buy-now-text'>
            <p>DESCUBRA OS</p>
            <p>SEGREDOS DA</p>
            <p>NUMEROLOGIA E</p>
            <p>TRANSFORME DESAFIOS</p>
            <p>EM OPORTUNIDADES.</p>
          </div>
          <strong>COMECE AGORA!</strong>
          <a href={linkClubeDosAutores} target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar loja clube dos autores', 'Visitar loja clube dos autores')}>
            <button>COMPRE AQUI</button>
          </a>          
        </div>
      </div>
    </div>
  );
}

export default BuyNow;
