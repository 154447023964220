import React from 'react';
import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Header from '../../components/PageComponents/Header';
import Ebook from '../../components/PageComponents/Ebook';
import Description from '../../components/PageComponents/Description';
import BuyNow from '../../components/PageComponents/BuyNow';
import Footer from '../../components/PageComponents/Footer';

import './styles.css';

const Landing: React.FC = () => {

  const pageName = 'pagina-principal'
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/', pageName);
  }, []);
  
  return (
    <div id="landing-page" className="container">      
      <Header pageName={pageName}/>
      <Ebook />
      <Description />
      <BuyNow pageName={pageName}/>
      <Footer pageName={pageName}/>
    </div>
  );
}

export default Landing;
