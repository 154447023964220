import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import './styles.css';

interface Props {  
  pageName: string, 
}
type props = Props

const Header = ({ pageName } : props) => {
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  //const linkAmazon = 'https://www.amazon.com.br/Manual-Numerologia-Ingrid-Dalila-Engel-ebook/dp/B0CP2XKT96/ref=sr_1_1?crid=2MF341MJESWFP&keywords=manual+de+numerologia&qid=1702656664&sprefix=%2Caps%2C411&sr=8-1';
  const linkClubeDosAutores = 'https://clubedeautores.com.br/livro/manual-de-numerologia';

  return (
    <header className='header'>
      <div className='header-content'>
        <div className='title'>
          <h1>MANUAL DE</h1>
          <h1>NUMEROLOGIA</h1>
        </div>
        <div className='header-block'>
          <h2>O SEU GUIA PRÁTICO E COMPLETO PARA</h2>
          <h2>INICIAR SUA JORNADA RUMO AO AUTOCONHECIMENTO</h2>
          <h2>E DESVENDAR UM UNIVERSO INFINITO DE POSSIBILIDADES</h2>
        </div>
        <a href={linkClubeDosAutores} target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar loja clube dos autores', 'Visitar loja clube dos autores')}>
          <button>COMPRE AQUI</button>
        </a>
      </div>
    </header>
  );
};

export default Header;
