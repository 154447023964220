import React from 'react';
import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';

import App from './App';
import ErrorPage from './pages/ErrorPage';
import Landing from './pages/Landing';

const publicRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },      
    ],
  },  
]);

const Routes: React.FC = () => {
  return (
    <RouterProvider router={publicRoutes} />
  );
}

export default Routes;
