import React from "react";

import capa from '../../../assets/images/capa.png';

import './styles.css';

const Ebook: React.FC = () => {
  return (
    <div className='ebook'>
      <div className='ebook-content'>
        <div className='ebook-text'>
          <h2>MANUAL DE NUMEROLOGIA</h2>
          <strong>(PRÁTICO E COMPLETO)</strong>
          <div className='text-block'>
            <p>Uma das portas para alcançar o Autoconhecimento e a Elevação
              Espiritual, a Numerologia convida leitores - leigos, iniciados ou que
              desejam se iniciar - a entrar na fascinante arte da interpretação
              dos números.</p>
            <p>Este Manual de Numerologia apresenta ao leitor, de forma prática e
              completa, um amplo estudo sobre a influência dos números no seu
              cotidiano, profissão, empresa, saúde, no seu projeto de viagem etc.</p>
            <p>Revela como a Numerologia está presente na vida de cada um. Com
              os elementos da Análise Numerológica aqui explicados, o leitor vai
              poder saber quais números, com seus respectivos atributos e
              vibrações, influenciam o seu nome, personalidade, alma,
              temperamento, tendências e quais os que o motivam a alcançar
              suas realizaçoes.</p>
            <p>Tudo com a ajuda da Cabala, da sabedoria dos Arcanos maiores do
              Tarô, dos hindus e de alguns grandes mestres espirituais da
              humanidade.</p>
            <p>Com um capitulo dedicado à Aplicação Prática, este Manual de
              Numerologia apresenta um Modelo de Interpretação, através da
              análise completa de caso.</p>
            <p>Disponibiliza ferramentas como tabelas de anos, meses, dias; dicas
              para assinaturas (grafologia), e Sinastria, a interpretação dos
              relacionamentos afetivos e amorosos.</p>
            <p>Com tópicos sobre a Numerologia Empresarial, este livro apresenta
              a gestores de empresas modelos de analise numerológica que
              auxiliam no diagnóstico e planejamento administrativo e funcional.</p>
            <p>Particularmente no que diz respeito ao relacionamento entre
              funcionários, entre diretores e o clima organizacional. No capitulo
              dedicado à Numerologia Tântrica deste livro, o leitor vai
              certamente ficar encantado com a união dos números com a
              sabedoria dos mestres hindus e dos autores.</p>
            <p>Numa abordagem holística, a interpretação dos números envolve
              todos os aspectos do individuo, com sugestões sobre
              comportamento, alimentação, saúde e desafios a serem vencidos.</p>
            <p>A Travessia dos 9 Portais do Conhecimento, por exemplo, descrita
              neste livro, é uma viagem maravilhosa para aqueles que se
              permitem, através dos números, conhecer suas vulnerabilidades e
              descobrir seus potenciais.</p>
          </div>
        </div>
        <div className='ebook-image'>
          <strong>E-BOOK E LIVRO</strong>
          <div className='capa'>
            <img src={capa} alt='capa_ebook_livro'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ebook;
